<template>
  <div>
    <div>
      <headerTop></headerTop>
    </div>
    <div>
      <headerMin @search="searchInit"></headerMin>
    </div>
    <div class="wrap search_content">
      <div  class="main"  v-if="infor!=null">
        <div >
			<div  class="title">{{infor.title}}</div>
			<div  class="txt">{{infor.content}}</div>
		</div>
      </div>
	  <div class="goodslist">
		  <div class="card" v-for="(i,index) in goods" :key="index" @click="getnextlevel(i)">
			  <div class="imgbox">
				  <img :src="i.goods_img" alt="">
			  </div>
			  <p class="price">{{i.price}}</p>
			  <p class="name">{{i.goods_name}}</p>
			  <p v-if="i.shop_name!=undefined" class="shopname">{{i.shop_name}}</p>
			  <p class="comment">评论<span>{{i.commentSum}}</span>条</p>
			  <button  v-if="i.shop_id!=undefined" class="btn">立即购买</button>
		  </div>
	  </div>
	  <div class="page" v-if="showbtn">
	  	<el-pagination background layout="prev, pager, next" :total="total" :page-size="15" @current-change="changePage"></el-pagination>
	  </div>
    </div>
    <div class="bg1">
      <publicBottom></publicBottom>
    </div>
  </div>
</template>
<script>
import headerTop from "@/components/public/public_headerTop.vue";
import headerMin from "@/components/index/index_header_min";
// import landChecking from "@/components/land-checking.vue";
import publicBottom from "@/components/public/public_bottom.vue";
import { getOnlineList } from "@/request/index";
export default {
  components: {
    headerTop,
    headerMin,
    publicBottom
  },
  props: [],
  data() {
    return {
      navState: 0,
      infor: "",
      txt: [],
      pharmList: [],
      search_txt: "",
	  page:1,
	  goods:[],
	  total:0,
	  showbtn:true
    };
  },
  created() {
    getOnlineList({
      cert_type: 3
    }).then(res => {
      if (res.code == 1) {
        this.pharmList = res.data.data;
        this.pharmList = this.pharmList.slice(0, 4);
      }
    });
    this.search_txt = this.$route.query.search;
	this.getgoods(this.search_txt);
  },
  methods: {
    GoAllgoods() {
      let msg = `/allGoods?search=${this.List1.title}`;
      this.$router.push({ path: msg });
    },
    searchInit(e) {
		this.search_txt=e;
	this.searchgoods(e);
	this.getgoods(e);
    },
    GoPharmIndex() {
      this.$router.push(`/pharmacist/index?search=${this.search_txt}`);
    },
    GoInquiryView(v) {
      this.$router.push(
        `/pharmacist/pharmDetails?id=${v.id}&uid=${v.uid}&waiting_num=${v.waiting_num}`
      );
    },
	getgoods(search_txt){
		this.$axios.post("/api/goods/index", {
			keyword: this.search_txt,
			pagenum:15,
			page: this.page,
		}).then(res => {
			if (res.data.code == 1) {
				this.goods= res.data.data.data;
				this.total = res.data.data.total;
				this.showbtn=true;
			}else{
				this.goods= [];
				this.total =0;
				this.showbtn=false;
			}
		});
	},
	GoGoodsOrder(v) {
		this.$router.push({
			name: "商品详情",
			query: {
				id: v.id,
				shopId: v.shop_id
			}
		});
	},
	getnextlevel(v){
		if(v.shop_id!=undefined){
			this.GoGoodsOrder(v);
		}else{
			this.$axios.post("api/Goods/index2", {
				goods_id: v.id,
				is_daili: 0,
				page:1,
			}).then(res => {
				if (res.data.code == 1) {
					this.total=res.data.data.total;
					this.goods = res.data.data.data;
					console.log(this.goodsList);
				}
			})
		}
	},
	searchgoods(value){
		this.$axios.post("/api/index/search",{
			keyword:value
		}).then(res=>{
			if(res.data.code==1){
				this.infor=res.data.data.disease_info;
			}
		})
	},
	changePage(page) {
		this.page = page;
		this.getgoods();
	}
  },
  computed: {}
};
</script>
<style lang="less" >
@import "../../assets/less/index";
.page {
		width: 100%;
		text-align: center;
		margin-top: 20px;
	}
.goodslist{
	width: 1200px;
	min-width: 1200px;
	display: flex;
	flex-wrap: wrap;
	.card{
		width: 19%;
		border:1px solid #ccc;
		margin-top: 20px;
		margin-right: 10px;
		padding: 10px;
		p{
			margin: 10px 0;
		}
		.imgbox{
			width: 100%;
			height: 180px;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.price{
			font-size: 24px;
			color: red;
		}
		.name{
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.shopname{
			font-size: 14px;
			color: #555555;
		}
		.comment{
			span{
				color: red;
			}
		}
		.btn{
			width: 100%;
			height: 34px;
			background-color: red;
		}
	}
}
</style>